import React from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

const configuration = {
  storePaymentMethod: true,
  environment: '', 
  clientKey: '',
  analytics: {
    enabled: true
  },
  session: {
    id: '',
    sessionData: '' 
  },
  onPaymentCompleted: (result, component) => {
    console.info('receive succes', result, component);
    // TODO: change url later?
    window.location.replace('https://localhost?isSuccessed=true');      
  },
  onError: (error, component) => {
    console.error('receive failure',error.name, error.message, error.stack, component);
    // TODO: change url later?
    window.location.replace('https://localhost?isSuccessed=false');
      
  }
};

export class Adyen extends React.Component {
  constructor() {
    super();
    this.state = { data: [] };
  }

  componentDidMount() {    
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    var id = urlParams.get('id');
    var data = urlParams.get('data');
    var clientKey = urlParams.get('k');
    var env = urlParams.get('e');
    
    configuration.session.id = id;
    configuration.session.sessionData = data;
    configuration.clientKey = clientKey;
    configuration.environment = env;
    AdyenCheckout(configuration).then((checkout)=>{
      checkout.create('dropin').mount('#dropin-container');
    });

    
 }

  render() {
    return <div id="dropin-container"></div>;
  }
}