import React from "react";
import { Adyen } from "./app/adyen/Adyen";
import "./App.css";

export default class App extends React.Component 
{
  constructor() {
    super();
    this.state = { data: [], psp: '' };
  }
  
  render(){
    return   <>
    <div className="container">     
            <Adyen></Adyen>;
    </div>
  </>
  }
}